import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { TRAINER_DASHBOARD, TRAINER_ADEPTS, TRAINER_ADEPTS_MANAGE, TRAINER_USER_OFFER_DIAGNOSTIC_REPORT_CREATE } from 'Consts/routes';

import { withVariables } from 'Utils/string';
import { getFullName } from 'Utils/user';

import Editor from 'Components/trainer/userOfferDiagnosticReports/Editor';
import StyledComponent from 'Components/core/StyledComponent';
import PageContent from 'Components/layout/panel/PageContent';
import TabNavigation from 'Components/layout/panel/TabNavigation';
import { TAB_USER_OFFERS } from 'Components/pages/trainer/UsersManage/component';

export const TAB_DATA = 'data';

export default class TrainerUserOfferDiagnosticReportsManage extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        match: PropTypes.shape({
            params: PropTypes.shape({
                id: PropTypes.string.isRequired,
            }).isRequired,
        }).isRequired,
        actions: PropTypes.shape({
            single: PropTypes.func.isRequired,
        }).isRequired,
        userOfferDiagnosticReport: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            isLoaded: PropTypes.bool.isRequired,
            data: PropTypes.object,
        }),
    };
    static defaultProps = {};
    state = {};

    componentDidMount = () => {
        this.loadData();
    }

    componentDidUpdate = prevProps => {
        const { match } = this.props;
        const { params } = match;

        if (prevProps.match.params.id !== params.id) {
            this.loadData();
        }
    }

    loadData = () => {
        const { actions, match } = this.props;
        const { params } = match;

        actions.single({ id: params.id });
    }

    render() {
        const { location, history, userOfferDiagnosticReport } = this.props;

        return (
            <StyledComponent
                className="trainer-user-offer-diagnostic-reports"
                styles={require('./styles')}
            >
                <PageContent
                    elementMode={true}
                    data={userOfferDiagnosticReport}
                    breadcrumbs={[{
                        to: withVariables(TRAINER_DASHBOARD.path),
                        label: 'Dashboard',
                    }, {
                        to: withVariables(TRAINER_ADEPTS.path),
                        label: 'Adepci',
                    }, {
                        to: withVariables(TRAINER_ADEPTS_MANAGE.path, { id: userOfferDiagnosticReport && userOfferDiagnosticReport.data && userOfferDiagnosticReport.data.user.id }),
                        label: getFullName(userOfferDiagnosticReport && userOfferDiagnosticReport.data && userOfferDiagnosticReport.data.user).label,
                    }, {
                        to: withVariables(TRAINER_ADEPTS_MANAGE.path, { id: userOfferDiagnosticReport && userOfferDiagnosticReport.data && userOfferDiagnosticReport.data.user.id }, { tab: TAB_USER_OFFERS }),
                        label: 'Zamówienia',
                    }]}
                >
                    {userOfferDiagnosticReport && userOfferDiagnosticReport.data && (
                        <TabNavigation
                            location={location}
                            history={history}
                            headline="Raport diagnostyczny"
                            tabs={[{
                                key: TAB_DATA,
                                label: 'Dane',
                                children: (
                                    <Editor
                                        location={location}
                                        history={history}
                                        data={userOfferDiagnosticReport.data}
                                    />
                                ),
                            }]}
                        />
                    )}
                </PageContent>
            </StyledComponent>
        );
    }
}

